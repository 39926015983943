export const zoneStatusData = {
  1: {
    status: 'Normal',
    color: '#F2F2F2',
    state: 'ALL',
  },
  6: {
    status: 'Advisory',
    color: '#6A95CB',
    state: 'ALL',
  },
  2: {
    status: 'Evacuation Warning',
    color: '#E5C447',
    state: 'ALL',
  },
  3: {
    status: 'Evacuation Order',
    color: '#D37072',
    state: 'ALL',
  },
  4: {
    status: 'Evacuation Order Lifted',
    color: '#90D260',
    state: 'ALL',
  },
  5: {
    status: 'Shelter in Place',
    color: '#BF6ADC',
    state: 'ALL',
  },
  7: {
    status: 'Training',
    color: '#426769',
    state: 'ALL',
  },
  8: {
    status: 'Evacuation Level 1: Be Ready',
    color: '#90D260',
    state: 'OREGON',
  },
  9: {
    status: 'Evacuation Level 2: Be Set',
    color: '#E5C447',
    state: 'OREGON',
  },
  10: {
    status: 'Evacuation Level 3: Go Now',
    color: '#D37072',
    state: 'OREGON',
  },
};
