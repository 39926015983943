import React, { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { zoneStatusData } from '~/constants/zoneStatus';
import { Box, ListItemIcon } from '@material-ui/core';
import Icon from '~/components/Icon';
import { TextField, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '~/redux-rtk/hooks';
import { AppActions } from '~/redux-rtk/slices/appSlice';

const getMenuItem = (
  iconName: string,
  color: string,
  value: number,
  label: string,
) => {
  return (
    <MenuItem
      value={value}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        width: '100%',
        gap: '5px',
      }}
    >
      <ListItemIcon style={{ minWidth: '0' }}>
        <Icon name={iconName} color={color} size={20}></Icon>
      </ListItemIcon>
      <Typography variant="body1">{label}</Typography>
    </MenuItem>
  );
};

export default function StatusSelector() {
  const dispatch = useAppDispatch();
  const statusSelected = useAppSelector(
    state => state.app.data.expiredAlertFilters.zoneStatus,
  );
  const updateFilters = (value: { zoneStatus: number }) => {
    dispatch(AppActions.updateExpiredAlertFilters(value));
  };

  const handleChange = (event: any) => {
    updateFilters({ zoneStatus: event.target.value });
  };

  const statusOptions = () => {
    const options = Object.values(zoneStatusData).map((zoneStatus, index) => {
      return getMenuItem(
        'StatusDot',
        zoneStatus.color,
        index + 1,
        zoneStatus.status,
      );
    });
    return [...options, getMenuItem('SelectAll', '', 0, 'All')];
  };

  return (
    <Select
      id="status-select"
      placeholder=""
      value={statusSelected}
      onChange={data => handleChange(data)}
      variant="outlined"
      size="small"
      sx={{
        '.MuiSelect-select': { display: 'flex', gap: '5px' },
        minHeight: 0,
      }}
    >
      {statusOptions()}
    </Select>
  );
}
