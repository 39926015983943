import { Box, Typography, useTheme } from '@mui/material';
import { Point } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getCoordsForSearchSuggestion } from '~/api';
import SearchAddressOrZone from '~/components/SearchInput/index';
import TabsComponent from '~/components/TabsComponent';
import { Color } from '~/constants';
import { useIsMobileDevice } from '~/hooks';
import { I18n } from '~/i18n';
import { ZonesApi } from '~/redux-rtk';
import { useAppDispatch, useAppSelector } from '~/redux-rtk/hooks';
import {
  AlertsSortMethod,
  AlertStatusType,
  AppActions,
  CalendarMenu,
  ExpiredAlertFilters,
} from '~/redux-rtk/slices/appSlice';
import { paramsToObject } from '~/utils/params';

import TabPanelActive from './TabPanelActive';
import TabPanelExpired from './TabPanelExpired';
import { SearchParameters, SortMethod } from './types';

// eslint-disable-next-line import/order

const tabData = [
  { id: AlertStatusType.Active, label: I18n.t('alertsList.tabLabelActive') },
  { id: AlertStatusType.Expired, label: I18n.t('alertsList.tabLabelExpired') },
];

const AlertsList = () => {
  const theme = useTheme();
  const isMobile = useIsMobileDevice();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchCoordinates, setSearchCoordinates] = useState<Point | null>();

  const sortMethod = useAppSelector(state => state.app.data.selectedAlertSort);
  const alertCritical = useAppSelector(state => state.app.data.alertCritical);
  const alertSearchTerm = useAppSelector(
    state => state.app.data.alertSearchTerm,
  );
  const alertStatus = useAppSelector(state => state.app.data.alertStatus);
  const alertPageSelected = useAppSelector(
    state => state.app.data.alertPageSelected,
  );
  const userLocation = useAppSelector(state => state.user.data.currentLocation);
  const mapCenter = useAppSelector(state => state.map.data.viewport.center);
  const dispatch = useAppDispatch();
  const expiredAlertFilters = useAppSelector(
    state => state.app.data.expiredAlertFilters,
  );

  useEffect(() => {
    // The first time we load the 'Alerts List' page, update the app's "selected sort, critical, search" based on query
    // params, if applicable
    const querySortMethod = searchParams.get('sort') as AlertsSortMethod;
    const queryAlertCritical = searchParams.get('critical');
    const queryAlertSearch = searchParams.get('search');
    const queryAlertStatus = searchParams.get('status') as AlertStatusType;
    if (Object.values(AlertsSortMethod).includes(querySortMethod)) {
      dispatch(AppActions.selectAlertSort(querySortMethod));
    }

    if (Object.values(AlertStatusType).includes(queryAlertStatus)) {
      dispatch(AppActions.changeAlertStatus(queryAlertStatus));
    }

    if (queryAlertSearch?.length >= 0) {
      dispatch(AppActions.updateAlertSearchTerm(queryAlertSearch));
    }

    if (queryAlertCritical === 'true') {
      dispatch(AppActions.changeAlertCritical(true));
    }
  }, []);

  //separate the filters of active/expired before sending it to the api for easier processing
  const getAlertFilters = () => {
    if (alertStatus === AlertStatusType.Active) {
      //only active alert filters
      return {
        activeOnly: true,
        criticalOnly: alertCritical,
      };
    } else {
      //only expired alert filters
      const expiredFilters: ExpiredAlertFilters = {};
      if (expiredAlertFilters?.zoneId) {
        expiredFilters.zoneId = expiredAlertFilters.zoneId;
      }
      if (expiredAlertFilters.zoneStatus) {
        expiredFilters.zoneStatus = expiredAlertFilters.zoneStatus;
      }
      if (expiredAlertFilters?.time?.selectedMenu !== CalendarMenu.all) {
        expiredFilters.time = expiredAlertFilters.time;
      }
      return {
        ...expiredFilters,
        activeOnly: false,
        criticalOnly: alertCritical,
      };
    }
  };

  const searchCoordinatesPayLoad = (): Point => {
    if (
      sortMethod === AlertsSortMethod.Nearest &&
      alertStatus === AlertStatusType.Active
    ) {
      return {
        x: userLocation?.coordinates?.[0],
        y: userLocation?.coordinates?.[1],
      };
    }
    return searchCoordinates;
  };

  const alertSortPayload = (): SortMethod => {
    switch (sortMethod) {
      case AlertsSortMethod.Latest:
        return {
          type: 'time',
          direction: 'DESC',
        };
      case AlertsSortMethod.County:
        return {
          type: 'county',
          direction: 'ASC',
        };
      case AlertsSortMethod.Nearest:
        return {
          type: 'proximity',
        };
    }
  };

  /**
   * @pagesettings common pagesize, pagenumber for both active and expired alerts
   * @filters common attribute for both alerts but only one will be used at a time
   * @coordinates only used in active alerts for sorting nearest (uses sort: 'proximity')
   * @sortmethod only used in active alerts for sorting
   **/
  const alertResponse = ZonesApi.useFetchAlertsQuery({
    pageSettings: {
      page: alertPageSelected,
      size: alertStatus === AlertStatusType.Active ? 5 : 10,
    },
    filters: getAlertFilters(),
    coordinates: searchCoordinatesPayLoad(),
    sortMethod: alertSortPayload(),
  });

  const getCoordsFromSuggestion = async item => {
    let coords: Point;
    if (item) {
      const geoCodeReponse = await getCoordsForSearchSuggestion(
        item,
        mapCenter,
      );
      // not sure why x,y are inverted in the geocode api, but the other way around is not working
      coords = geoCodeReponse
        ? {
            y: geoCodeReponse?.candidates[0]?.location?.x,
            x: geoCodeReponse?.candidates[0]?.location?.y,
          }
        : null;
    }
    setSearchCoordinates(coords);
  };

  useEffect(() => {
    const searchParameters: SearchParameters = {
      ...paramsToObject(searchParams),
      critical: alertCritical.toString(),
      status: alertStatus,
    };
    if (sortMethod) {
      searchParameters.sort = sortMethod;
    }
    if (alertSearchTerm?.length >= 0) {
      searchParameters.search = alertSearchTerm;
    }
    setSearchParams(searchParameters);
  }, [sortMethod, alertCritical, alertSearchTerm, alertStatus, alertResponse]);

  return (
    <Box
      sx={{
        padding: `${isMobile ? '16px' : '32px'}`,
        height: '100%',
        width: '100%',
        overflow: 'scroll',
      }}
    >
      <Box>
        <Typography
          variant="h1"
          sx={{ mb: 1, color: theme.palette.secondary.main }}
        >
          {I18n.t('alertsList.title')}
        </Typography>
        <Box
          sx={{
            mb: 1,
            width: '75%',
            color: Color.NeutralGrey,
          }}
        >
          <Typography variant="body1">
            {I18n.t('alertsList.subTitle')}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          mt: theme.spacing(2),
          maxWidth: '300px',
        }}
      >
        <SearchAddressOrZone
          placeHolderText={I18n.t('inputsPlaceholder.searchAlert')}
          onInputSelect={(value, action) => {
            getCoordsFromSuggestion(value);
          }}
          onInputValueChange={(value, action) => {}}
        />
      </Box>
      <TabsComponent
        tabsData={tabData}
        onTabChange={(event, id) => {
          dispatch(AppActions.changeAlertStatus(id as AlertStatusType));
          //the page should reset to zero on tab change to start pagination from the begining
          dispatch(AppActions.updateAlertPageSelected(0));
        }}
        activeTab={alertStatus}
      >
        <TabPanelActive
          selectedTab={AlertStatusType.Active}
          alertDetails={alertResponse.data}
        />
        <TabPanelExpired
          selectedTab={AlertStatusType.Expired}
          alertDetails={alertResponse.data}
        />
      </TabsComponent>
    </Box>
  );
};

export default AlertsList;
