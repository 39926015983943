import SwapVertIcon from '@mui/icons-material/SwapVert';
import { Box, styled, Typography } from '@mui/material';
import React from 'react';

import SelectComponent from '~/components/SelectComponent/SelectComponent';
import SwitchComponent from '~/components/SwitchComponent';
import { Color } from '~/constants';
import ActiveAlertList from '~/containers/AlertsList/ActiveAlertList';
import { useIsMobileDevice } from '~/hooks';
import { I18n } from '~/i18n';
import { useAppDispatch, useAppSelector } from '~/redux-rtk/hooks';
import { AlertsSortMethod, AppActions } from '~/redux-rtk/slices/appSlice';
import { theme } from '~/theme';

import { AlertDetails } from './types';

type TabPanelActiveProps = {
  selectedTab: string;
  alertDetails: AlertDetails;
};

const options = [
  { value: 'latest', label: I18n.t('alertsList.latest') },
  { value: 'nearest', label: I18n.t('alertsList.nearest') },
  { value: 'county', label: I18n.t('alertsList.county') },
];

const VerticalSwapIcon = styled(SwapVertIcon)(() => ({
  color: Color.NeutralGrey,
}));

const TabPanelActive = ({ selectedTab, alertDetails }: TabPanelActiveProps) => {
  const sortMethod = useAppSelector(state => state.app.data.selectedAlertSort);
  const alertCritical = useAppSelector(state => state.app.data.alertCritical);
  const dispatch = useAppDispatch();
  const isMobile = useIsMobileDevice();

  const handleSortSelection = (sortEvent: React.ChangeEvent<any>) => {
    dispatch(
      AppActions.selectAlertSort(sortEvent.target.value as AlertsSortMethod),
    );
  };

  const toggleCriticalFilter = () => {
    dispatch(AppActions.changeAlertCritical(!alertCritical));
  };

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${selectedTab}`}
      aria-labelledby={`simple-tab-${selectedTab}`}
      style={{
        border: '0px !important',
        width: `${isMobile ? '100%' : '70%'}`,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <VerticalSwapIcon sx={{ height: '16px', width: '16px' }} />
          <Typography variant="body2" sx={{ color: Color.NeutralGrey }}>
            {I18n.t('alertsList.sortBy')}
          </Typography>
          <Box sx={{ display: 'flex', color: theme.palette.secondary.main }}>
            <SelectComponent
              options={options}
              selectedValue={sortMethod}
              onChange={e => {
                handleSortSelection(e);
              }}
              showDropdownArrow={false}
              showInputBorder={false}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            color: theme.palette.secondary.main,
          }}
        >
          <Typography variant="body2">
            {I18n.t('alertsList.onlyCritical')}
          </Typography>
          <SwitchComponent
            checked={alertCritical}
            onChange={toggleCriticalFilter}
          />
        </Box>
      </Box>
      <ActiveAlertList alertDetails={alertDetails} />
    </div>
  );
};

export default TabPanelActive;
