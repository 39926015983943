import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import Icon from '~/components/Icon';
import { I18n } from '~/i18n';
import { useAppDispatch, useAppSelector } from '~/redux-rtk/hooks';
import {
  AppActions,
  CalendarMenu,
  TimeFilter,
} from '~/redux-rtk/slices/appSlice';
import { theme } from '~/theme';

import PersonalisedPeriod from './PersonalisedPeriod';
import { ALERTDATEFORMAT } from '~/constants/common';

export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const updateFilters = (value: TimeFilter) => {
    dispatch(AppActions.updateExpiredAlertFilters({ time: value }));
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (
    menuType: CalendarMenu,
    fromDate?: any,
    toDate?: any,
  ) => {
    updateFilters({
      toDate: toDate,
      selectedMenu: menuType,
      fromDate: fromDate,
    });
    setAnchorEl(null);
  };

  const calendarSettings = useAppSelector(
    state => state.app.data.expiredAlertFilters.time,
  );

  const today = () => {
    return dayjs().format(ALERTDATEFORMAT);
  };

  const lastSevenDays = () => {
    return dayjs().subtract(7, 'day').format(ALERTDATEFORMAT);
  };

  const lastThirtyDays = () => {
    return dayjs().subtract(30, 'day').format(ALERTDATEFORMAT);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="outlined"
        endIcon={<KeyboardArrowDownIcon />}
        startIcon={<Icon name="Calendar" color="black" />}
        sx={{
          borderRadius: theme.spacing(0.5),
        }}
      >
        {calendarSettings?.selectedMenu}
      </Button>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
        sx={{
          '& .MuiPaper-root': {
            border: '1px solid grey',
            borderRadius: '5px',
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleMenuClick(CalendarMenu.all);
          }}
        >
          {I18n.t('alertsList.all')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClick(CalendarMenu.today, today());
          }}
        >
          {I18n.t('alertsList.today')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClick(
              CalendarMenu.lastSevenDays,
              lastSevenDays(),
              today(),
            );
          }}
        >
          {I18n.t('alertsList.lastSevenDays')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClick(
              CalendarMenu.lastThirtyDays,
              lastThirtyDays(),
              today(),
            );
          }}
        >
          {I18n.t('alertsList.lastThirtyDays')}
        </MenuItem>
        <MenuItem>
          <PersonalisedPeriod closeMenu={handleClose} />
        </MenuItem>
      </Menu>
    </div>
  );
}
