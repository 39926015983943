import { Button, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { I18n } from '~/i18n';
import { useAppDispatch, useAppSelector } from '~/redux-rtk/hooks';
import { AppActions } from '~/redux-rtk/slices/appSlice';
import {
  extractDateTime,
  getDefaultBrowserLanguage,
  getTranslatedText,
} from '~/utils/alertsUtils';

import EnhancedTableHead from './ExpiredAlertTableHead';
import { AlertCardData, AlertDetails } from '../../../pages/AlertsList/types';

// should be removed once api is available
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

// should be removed once api is available
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function ExpiredAlertsTable({
  alertDetails,
}: {
  alertDetails: AlertDetails;
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof AlertCardData>('counties');
  const rowsPerPage = 1;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof AlertCardData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, value: number) => {
    dispatch(AppActions.updateAlertPageSelected(value));
  };
  const alertPageSelected = useAppSelector(
    state => state.app.data.alertPageSelected,
  );

  const handleViewDetailClick = (alertData: AlertCardData) => {
    dispatch(AppActions.updateSelectedAlertData(alertData));
    navigate(`/alert`);
  };

  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <Paper sx={{ width: '100%' }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {alertDetails?.content?.map((row, index) => {
                const [date, time] = extractDateTime(row.updatedAt);
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell align="left">
                      {row?.source === 'EVAC'
                        ? row?.status?.description
                        : getTranslatedText(
                            row?.titles,
                            getDefaultBrowserLanguage(),
                          )}
                    </TableCell>
                    <TableCell align="left">
                      {row?.counties?.length > 1
                        ? I18n.t('alertsList.multipleCounties')
                        : row?.counties[0]?.name}
                    </TableCell>
                    <TableCell align="left">{date}</TableCell>
                    <TableCell align="left">{time}</TableCell>
                    <TableCell align="left">
                      <Button
                        variant="outlined"
                        sx={{
                          borderRadius: '5px',
                          height: theme.spacing(3),
                          padding: theme.spacing(1),
                          '&:hover': {
                            borderColor: theme.palette.primary.light,
                          },
                        }}
                        onClick={() => handleViewDetailClick(row)}
                      >
                        {I18n.t('alertsList.view')}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          component="div"
          count={alertDetails?.totalElements}
          rowsPerPageOptions={[]}
          rowsPerPage={alertDetails?.size}
          page={alertPageSelected}
          onPageChange={handleChangePage}
        />
      </Paper>
    </Box>
  );
}
