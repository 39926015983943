import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import StatusSelector from './StatusSelector';
import DateSelector from './Calendar/DateSelector';
import SearchZoneId from './SearchZoneId';
import SwitchComponent from '~/components/SwitchComponent';
import { useAppDispatch, useAppSelector } from '~/redux-rtk/hooks';
import { theme } from '~/theme';
import { I18n } from '~/i18n';
import { AppActions, CalendarMenu } from '~/redux-rtk/slices/appSlice';
import Icon from '~/components/Icon';
import { Color } from '~/constants';

export default function ExpiredAlertFilters() {
  const dispatch = useAppDispatch();
  const alertCritical = useAppSelector(state => state.app.data.alertCritical);
  const filters = useAppSelector(state => state.app.data.expiredAlertFilters);
  const toggleCriticalFilter = () => {
    dispatch(AppActions.changeAlertCritical(!alertCritical));
  };
  const clearFilters = () => {
    dispatch(AppActions.resetExpiredAlertFilters());
  };
  const filterSelected =
    filters.zoneId ||
    filters.zoneStatus > 0 ||
    filters.time?.selectedMenu !== CalendarMenu.all;
  return (
    <Box
      sx={{
        display: 'flex',
        gap: theme.spacing(5),
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <SearchZoneId />
      <StatusSelector />
      <DateSelector />
      {filterSelected && (
        <Button
          onClick={() => clearFilters()}
          startIcon={<Icon name="Close" color={theme.palette.primary.main} />}
          size="small"
          sx={{
            marginLeft: theme.spacing(-3),
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.secondary.main,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <Typography
            variant="caption"
            style={{ color: theme.palette.primary.main, fontWeight: 600 }}
          >
            {I18n.t('alertsList.clear')}
          </Typography>
        </Button>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          color: theme.palette.secondary.main,
          marginLeft: theme.spacing(7),
        }}
      >
        <Typography variant="body2">
          {I18n.t('alertsList.onlyCritical')}
        </Typography>
        <SwitchComponent
          checked={alertCritical}
          onChange={toggleCriticalFilter}
        />
      </Box>
    </Box>
  );
}
