import parse from 'wellknown';

import { Color } from '~/constants';
import { zoneStatusData } from '~/constants/zoneStatus';
import { AlertCardData } from '~/pages/AlertsList/types';
import { CalendarMenu, TimeFilter } from '~/redux-rtk/slices/appSlice';

export const extractDateTime = (isoString: string) => {
  const date = new Date(isoString);

  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const year = date.getUTCFullYear();
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Format the date and time
  const formattedDate = `${month}-${day}-${year}`;
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  return [formattedDate, formattedTime];
};

export const getNativeLanguageNames = languageCodes => {
  return languageCodes.map(code => {
    const displayNames = new Intl.DisplayNames([code], { type: 'language' });
    return {
      languageCode: code,
      displayName: displayNames.of(code),
    };
  });
};

export const getTranslatedText = (alertData, currentLanguage) => {
  if (alertData) {
    return (
      alertData?.find(data => data.id === currentLanguage)?.text ||
      alertData?.find(data => data.id === 'en')?.text
    );
  }
  return null;
};

export const getDefaultBrowserLanguage = () => {
  return (
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    'en'
  );
};

export const getAlertColor = (alertData: AlertCardData) => {
  if (!alertData?.active) {
    return Color.Grey600;
  }
  if (alertData?.source === 'EVAC') {
    return zoneStatusData[alertData?.status?.id]?.color;
  }
  return Color.UniformBlue;
};

export const convertWktToGeoJson = wkt => {
  try {
    return parse(wkt);
  } catch (error) {
    return null;
  }
};

export const getDateFilters = (timeFilter: TimeFilter) => {
  switch (timeFilter.selectedMenu) {
    case CalendarMenu.today:
      return [
        {
          property: 'time',
          operator: 'eq',
          value: timeFilter?.fromDate,
        },
      ];
    case CalendarMenu.lastSevenDays:
      return [
        {
          property: 'time',
          operator: 'gte',
          value: timeFilter?.fromDate,
        },
      ];
    case CalendarMenu.lastThirtyDays:
      return [
        {
          property: 'time',
          operator: 'gte',
          value: timeFilter?.fromDate,
        },
      ];
    case CalendarMenu.personalisedPeriod:
      return [
        {
          property: 'time',
          operator: 'gte',
          value: timeFilter?.fromDate,
        },
        {
          property: 'time',
          operator: 'lte',
          value: timeFilter?.toDate,
        },
      ];
  }
};
