import { memo, useEffect, useState } from 'react';
import { Layer, LayerProps, Source, SourceProps } from 'react-map-gl/maplibre';

import { CacheTimeoutPeriod } from '~/constants';
import { useFetchMapDataUpdatesQuery } from '~/redux-rtk';
import { getWMTS } from '~/utils';

import { Tile } from '../config/tiles';

interface LayersProps {
  tiles: Tile[];
  layers: (LayerProps[] | LayerProps)[];
  forceRerenderNumber?: number;
}

const WsLayerUpdateKeys: LayersToUpdate[] = [
  'statusUpdate',
  'splitMergeUpdate',
  'arrivalPointUpdate',
  'tcpUpdate',
];

type LayersToUpdate =
  | 'statusUpdate'
  | 'splitMergeUpdate'
  | 'arrivalPointUpdate'
  | 'tcpUpdate';

const UnmemoedLayers = ({ tiles, layers }: LayersProps): JSX.Element => {
  return (
    <>
      {layers.map((layerGroup, index) => {
        const {
          name = '',
          type,
          gridSet,
          tileMatrix,
          maxzoom,
          minzoom,
          promoteId,
          sourceProvider,
        } = tiles[`${index}`];

        const sourceProps: SourceProps = {
          type,
          id: name,
          tiles: [getWMTS(name, gridSet, tileMatrix, sourceProvider)],
          ...(maxzoom ? { maxzoom } : {}),
          ...(minzoom ? { minzoom } : {}),
          ...(promoteId ? { promoteId } : {}),
        };

        return (
          <Source key={name} {...sourceProps}>
            {Array.isArray(layerGroup) ? (
              layerGroup.map(layerGroup => (
                <Layer key={layerGroup.id} {...layerGroup} />
              ))
            ) : (
              <Layer {...layerGroup} />
            )}
          </Source>
        );
      })}
    </>
  );
};

// There appears to be a bug where if Layers is rendered many times it can
// end up in a weird state or not rendering the layers at all. React.memo
// ensure Layers only re-renders when the layers or tiles prop change.
const MemoedLayers = memo(
  UnmemoedLayers,
  (prevProps, nextProps) =>
    prevProps.forceRerenderNumber === nextProps.forceRerenderNumber,
);

export const LayersWrapper = (props: LayersProps) => {
  const [forceRerenderNumber, setForceRerenderNumber] = useState(0);
  const { data: webSocketUpdates } = useFetchMapDataUpdatesQuery();

  useEffect(() => {
    // If any of the updated layers match our whitelist of updates that should trigger a map refresh
    if (
      (webSocketUpdates?.layersToUpdate ?? []).some(updatedLayer =>
        WsLayerUpdateKeys.includes(updatedLayer as LayersToUpdate),
      )
    ) {
      setTimeout(() => {
        setForceRerenderNumber(forceRerenderNumber + 1);
      }, CacheTimeoutPeriod);
    }
  }, [webSocketUpdates]);

  return <MemoedLayers {...props} forceRerenderNumber={forceRerenderNumber} />;
};
