import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { FormControl, InputLabel, TextField } from '@mui/material';
import { I18n } from '~/i18n';
import { useAppDispatch, useAppSelector } from '~/redux-rtk/hooks';
import {
  AppActions,
  CalendarMenu,
  ExpiredAlertFilters,
  TimeFilter,
} from '~/redux-rtk/slices/appSlice';
import dayjs from 'dayjs';
import { ALERTDATEFORMAT } from '~/constants/common';

export default function PersonalisedPeriod({
  closeMenu,
}: {
  closeMenu: () => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const dispatch = useAppDispatch();
  const [dateRange, setDateRange] = useState({ from: '', to: '' });
  const [formErrors, setFormErrors] = useState({ from: '', to: '' });
  const updateFilters = (value: TimeFilter) => {
    dispatch(AppActions.updateExpiredAlertFilters({ time: value }));
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setFormErrors({ from: '', to: '' });
  }, [dateRange]);

  const menuItemStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  };

  const handleApply = () => {
    const newErrors = { from: '', to: '' };
    if (!dateRange.from) {
      newErrors.from = I18n.t('alertsList.required');
    }
    if (!dateRange.to) {
      newErrors.to = I18n.t('alertsList.required');
    }
    if (dayjs(dateRange.to).isBefore(dayjs(dateRange.from))) {
      newErrors.to = I18n.t('alertsList.dateRangeError');
    }
    setFormErrors(newErrors);
    if (newErrors.to || newErrors.from) return;
    updateFilters({
      fromDate: dayjs(dateRange.from).format(ALERTDATEFORMAT),
      selectedMenu: CalendarMenu.personalisedPeriod,
      toDate: dayjs(dateRange.to).format(ALERTDATEFORMAT),
    });
    handleClose();
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<KeyboardArrowRight sx={{ color: 'black' }} />}
        variant="text"
        sx={{ margin: 0, padding: 0, marginLeft: 'auto', color: 'black' }}
      >
        {I18n.t('alertsList.personalisedPeriod')}
      </Button>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            border: '1px solid grey',
            borderRadius: '5px',
          },
          '& .MuiList-root': {
            padding: 0,
          },
        }}
      >
        <MenuItem sx={menuItemStyles}>
          <InputLabel> {I18n.t('alertsList.from')}</InputLabel>
          <TextField
            required
            error={!!formErrors.from}
            id=""
            onChange={event => {
              setDateRange({ ...dateRange, from: event.target.value });
            }}
            helperText={formErrors.from}
            type="date"
            value={dateRange.from}
            sx={{
              '& .MuiInputBase-root': {
                minHeight: '0',
              },
            }}
          />
        </MenuItem>
        <MenuItem sx={menuItemStyles}>
          <InputLabel> {I18n.t('alertsList.to')}</InputLabel>
          <TextField
            required
            error={!!formErrors.to}
            onChange={event => {
              setDateRange({ ...dateRange, to: event.target.value });
            }}
            value={dateRange.to}
            helperText={formErrors.to}
            type="date"
            sx={{
              '& .MuiInputBase-root': {
                minHeight: '0',
              },
            }}
          />
        </MenuItem>
        <MenuItem sx={{ '&:hover': { backgroundColor: 'white' } }}>
          <Button
            size="small"
            id="button"
            sx={{ marginLeft: 'auto' }}
            onClick={handleApply}
          >
            {I18n.t('alertsList.apply')}
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
}
