import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { getAddressFromLocation } from '~/api';
import Icon from '~/components/Icon';
import { Color } from '~/constants';
import { useAppSelector } from '~/redux-rtk/';
import { theme } from '~/theme';
import { PropsWithSx } from '~/utils';

import { I18n } from '../../i18n/i18n';

interface Props {
  zoneId?: number | string;
  currentLocation?: boolean;
}

export const SelectedLocation = ({
  zoneId,
  currentLocation,
}: PropsWithSx<Props>) => {
  const selectedLocation = useAppSelector(
    state => state.app.data.selectedLocation,
  );

  const userLocation = useAppSelector(state => state.user.data.currentLocation);

  const [selectedAddress, setSelectedAddress] = useState('');
  useEffect(() => {
    getAddressFromLocation(
      currentLocation
        ? (userLocation.coordinates as [number, number])
        : (selectedLocation?.position as [number, number]),
      selectedLocation?.zoom,
    )
      .then(response => {
        if (response?.address) {
          setSelectedAddress(response.address?.LongLabel);
        }
      })
      .catch(err => console.log(err));
  }, [selectedLocation, userLocation]);
  return (
    <Box sx={{ minHeight: 100 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: `${currentLocation ? 'row' : 'column'}`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: theme.spacing(1),
            }}
          >
            <Icon name="LocationShow" size={25} color={Color.DarkBlue}></Icon>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold' }}
              textTransform="uppercase"
            >
              {!currentLocation && I18n.t('myZones.selectedLocation')}
            </Typography>
          </Box>
          <Typography variant="body1" sx={{ marginLeft: '.3em' }}>
            {selectedAddress}
          </Typography>
        </Box>
        {zoneId && (
          <Typography variant="body1" sx={{ marginLeft: '.3em' }}>
            <b>{I18n.t('myZones.zoneId')}</b>: {zoneId}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
