import { Box,Typography } from "@mui/material";
import { PropsWithSx } from "../../../../utils";

interface TitleProps {
    title: string;
    icon?: React.ReactNode;
}

export const H2Title = ({ title, icon, sx }: PropsWithSx<TitleProps>) => {
    return (
        <Box
            sx={{
                mb: 3,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                ...sx,
            }}
        >
            <Typography variant="h2">{title}</Typography>
            <Box sx={{ position: "relative", top: 2, right: 6 }}>{icon}</Box>
        </Box>
    );
};