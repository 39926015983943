import { Box, Button, Drawer, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as AppLogo } from '~/assets/images/logo/logo_protect_with_text_horizontal_black.svg';
import { focusableElementsString, MobileNavBarHeight } from '~/constants';
import { isMobileDownloadEnabled } from '~/flags';
import { useIsMobileDevice } from '~/hooks';
import { I18n } from '~/i18n';

import NavbarItem from './NavbarItem';

const NavItems = () => {
  return (
    <>
      <NavbarItem
        text={I18n.t('sections.map')}
        icon="MapSearch"
        link={'/search'}
      />
      <NavbarItem
        text={I18n.t('sections.alertsList')}
        icon="ListBullet"
        link={'/alerts'}
      />
      <NavbarItem
        text={I18n.t('sections.fires')}
        icon="Fire"
        link={'/hazards'}
      />
      <NavbarItem
        text={I18n.t('sections.about')}
        icon="About"
        link={'/about'}
      />
      {isMobileDownloadEnabled && (
        <NavbarItem
          text={I18n.t('sections.download')}
          icon="Mobile"
          link={'/download'}
        />
      )}
    </>
  );
};

export const Navbar = () => {
  const isMobile = useIsMobileDevice();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const currentPage = useLocation().pathname;
  const showNavMenu = currentPage !== '/fullscreenMap';

  const isScreenSmall = useMediaQuery(useTheme().breakpoints.down('md'));

  const drawerContentWrapperRef = useRef<HTMLDivElement>(null);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    if (!isScreenSmall && drawerOpen) {
      setDrawerOpen(false);
    }
  }, [isScreenSmall]);

  const handleKeydown = useCallback(event => {
    const isTabPressed = event.key === 'Tab' || event.keyCode === 9;
    if (!isTabPressed) {
      return;
    }
    const focusableElements =
      drawerContentWrapperRef?.current?.querySelectorAll(
        focusableElementsString,
      );
    const firstFocusableElement = focusableElements && focusableElements[0];
    const lastFocusableElement =
      focusableElements && focusableElements[focusableElements.length - 1];
    const closeBtn = document.getElementById('drawerCtrlBtn');

    // If Shift+Tab is pressed
    if (event.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        closeBtn?.focus();
        event.preventDefault();
        return;
      }
      if (document.activeElement === closeBtn) {
        (lastFocusableElement as HTMLElement)?.focus();
        event.preventDefault();
        return;
      }
    } else {
      if (document.activeElement === lastFocusableElement) {
        // Focus on the close button
        closeBtn?.focus();
        event.preventDefault();
        return;
      }
      if (document.activeElement === closeBtn) {
        // Focus on the first "focusable" element in the drawer
        const lastFocusableElement = focusableElements && focusableElements[0];
        (lastFocusableElement as HTMLElement)?.focus();
        event.preventDefault();
      }
    }
  }, []);

  useEffect(() => {
    if (drawerOpen) {
      document.addEventListener('keydown', handleKeydown);
    } else {
      document.removeEventListener('keydown', handleKeydown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [drawerOpen]);

  return (
    showNavMenu && (
      <>
        <Box
          sx={theme => ({
            width: '150px',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: 'inset -2px 0 4px rgba(0,0,0,.1)',
            backgroundColor: theme.palette.grey[50],
            [theme.breakpoints.up('md')]: {
              minWidth: '150px',
            },
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          })}
          component="header"
        >
          {!isMobile && (
            <Box
              sx={{
                p: 2,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Link to={'/'}>
                <AppLogo
                  width="100%"
                  height="auto"
                  data-testid="app-logo"
                  title={`${I18n.t('common.appName')} Logo`}
                  role="img"
                  aria-label={I18n.t('common.appName')}
                />
              </Link>
            </Box>
          )}

          <Box
            sx={theme => ({
              gap: 1,
              flex: 1,
              display: 'flex',
              listStyleType: 'none',
              flexDirection: 'column',
              [theme.breakpoints.down('md')]: {
                gap: 0,
                display: 'none',
              },
              overflowY: 'auto',
            })}
            component="nav"
            aria-label="Primary"
          >
            <NavItems />
          </Box>
        </Box>

        {/* For Mobile and small screen size */}
        <Box
          sx={theme => ({
            display: 'none',
            [theme.breakpoints.down('md')]: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: theme.spacing(1),
              backgroundColor: theme.palette.background.paper,
              position: 'sticky',
              top: 0,
              width: '100%',
              zIndex: 10,
              height: showNavMenu ? `${MobileNavBarHeight}px` : 0,
            },
          })}
          component="header"
        >
          <Link to={'/'}>
            <AppLogo
              width="50%"
              height="40px"
              data-testid="app-logo"
              title={`${I18n.t('common.appName')} Logo`}
              role="img"
              aria-label={I18n.t('common.appName')}
            />
          </Link>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDrawerToggle}
            id="drawerCtrlBtn"
          >
            {drawerOpen ? 'Close' : 'Menu'}
          </Button>
        </Box>

        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          sx={{
            '& .MuiDrawer-paper': {
              width: '100%',
              top: '56px',
              height: 'calc(100% - 56px)',
            },
            '&.MuiDrawer-modal': {
              inset: 'unset !important',
            },
          }}
          hideBackdrop
          disableEnforceFocus
        >
          <Box
            ref={drawerContentWrapperRef}
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
            onClick={handleDrawerToggle}
            component="nav"
            aria-label="Primary"
          >
            <NavItems />
          </Box>
        </Drawer>
      </>
    )
  );
};
