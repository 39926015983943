import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage

import { BaseMapApi, BaseZonehavenApi } from './api';
import { mapSlice, userSlice } from './slices';
import { appSlice } from './slices/appSlice';

// transform is used to persist only selectedAlertData
const selectedAlertDataTransform = createTransform(
  (inboundState: any) => {
    return { selectedAlertData: inboundState.selectedAlertData };
  },
  (outboundState: any) => {
    return outboundState;
  },
  { whitelist: ['selectedAlertData'] },
);

const appPersistConfig = {
  key: 'app',
  storage,
  transforms: [selectedAlertDataTransform],
};

const persistedAppReducer = persistReducer(appPersistConfig, appSlice.reducer);

export const store = configureStore({
  reducer: {
    [appSlice.name]: persistedAppReducer,
    [mapSlice.name]: mapSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    [BaseMapApi.reducerPath]: BaseMapApi.reducer,
    [BaseZonehavenApi.reducerPath]: BaseZonehavenApi.reducer,
  },
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(BaseMapApi.middleware, BaseZonehavenApi.middleware);
  },
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
