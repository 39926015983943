import {
  Autocomplete,
  FormControl,
  InputAdornment,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import Icon from '~/components/Icon';
import { Color } from '~/constants';
import { I18n } from '~/i18n';
import { useAppDispatch, useAppSelector } from '~/redux-rtk/hooks';
import { AppActions } from '~/redux-rtk/slices/appSlice';
import { isZoneId } from '~/utils';

export default function SearchZoneId() {
  const dispatch = useAppDispatch();
  const zoneIdSelected = useAppSelector(
    state => state.app.data.expiredAlertFilters.zoneId,
  );
  const updateFilters = (value: { zoneId: string }) => {
    dispatch(AppActions.updateExpiredAlertFilters(value));
  };

  // the api will get called as soon as we set redux state so -
  //- the local state is used to show the options
  const [zoneSearchOption, setZoneSearchOption] = useState('');

  const searchOptions = () => {
    if (isZoneId(zoneSearchOption)) {
      return [
        {
          label: `${I18n.t('zones.zone')}: ${zoneSearchOption.toUpperCase()}`,
          value: zoneSearchOption,
        },
      ];
    }
    return [];
  };

  return (
    <FormControl size="small">
      <Autocomplete
        id="autocomplete"
        freeSolo
        fullWidth
        size="small"
        inputValue={zoneSearchOption}
        onChange={(e, selected: any) => {
          updateFilters({ zoneId: selected?.value?.toUpperCase() });
        }}
        onInputChange={(e, newValue) => {
          setZoneSearchOption(newValue);
        }}
        sx={{
          minWidth: '170px',
          '& .MuiInputBase-root': {
            minHeight: '0',
          },
        }}
        options={searchOptions()}
        getOptionLabel={option => option?.label}
        renderInput={params => (
          <TextField
            {...params}
            label=""
            fullWidth
            placeholder={I18n.t('alertsList.searchZoneId')}
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start" sx={{ ml: '5px' }}>
                    <Icon name="Search" color={Color.DarkBlue} size={20} />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}
