import { useLocation, useSearchParams } from 'react-router-dom';
import { useIsFullscreen } from './useWindowSize';

// Hook to determine whether or not we consider this load of the application to be "focused," which
// we generally use to mean it's embedded in another application, or at the very least being loaded
// in a context where we don't want to bother the user with the onboarding modal; we just want to
// display the necessary information for them
export const useIsFocused = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const isFullscreen = useIsFullscreen();

  // We define the site as being "embedded" if one of the following are true:
  // * We have a preset lat lon and zoom (z)
  // * We have a selected zone
  // * We the window properties indicate this is being loaded in an iframe
  return (
    (searchParams.has('latlon') && searchParams.has('z')) ||
    location.pathname.includes('/zones/') ||
    (window.top !== window.self &&
      window.location !== window.parent.location) ||
    isFullscreen
  );
};
