import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';

import { Hazard } from '../components/Map/types';
import { useAppSelector, useFetchHazardsQuery } from '../redux-rtk';
import { HazardsSortMethod } from '../redux-rtk/slices/appSlice';

interface PaginatedHazardsData {
  isError: boolean;
  hazardsList: Hazard[];
  hasMore: boolean;
  fetchNextPage: () => void;
}

export const useFetchPaginatedHazards = (
  sortMethod: HazardsSortMethod,
  name: string
): PaginatedHazardsData => {
  const [hazardsList, setHazardsList] = useState<Hazard[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const currentLocation = useAppSelector(
    state => state.user.data.currentLocation,
  );

  useEffect(() => {
    setHazardsList([]);
    setOffset(0);
  }, [sortMethod, name]);

  const {
    data: hazardsData,
    isFetching,
    isError,
    isSuccess,
  } = useFetchHazardsQuery({
    location: currentLocation?.coordinates,
    offset: offset,
    sortBy: sortMethod,
    name
  });

  useEffect(() => {
    if (
      !isFetching &&
      isSuccess &&
      hazardsData?.nextOffset > hazardsList.length &&
      (!hazardsData?.lastPage || hazardsList.length === offset) &&
      hazardsData?.hazards.length > 0
    ) {
      setHazardsList(prev => prev.concat(cloneDeep(hazardsData.hazards)));
    }
  }, [isFetching, isSuccess, hazardsData, hazardsList.length, offset]);

  const fetchNextPage = () => {
    // The simplest way to force a refresh of the data is to update the offset; we use the latest
    // offset we were given from the most recent fetch and update our local variable, letting the fetch take over from there
    setOffset(hazardsData?.nextOffset || 0);
  };

  return {
    isError,
    hazardsList,
    hasMore: !hazardsData?.lastPage,
    fetchNextPage,
  };
};
